<template>
  <div>

    <div class="media-cover" v-bind:class="{'active': coverActive}"></div>

    <div class="container pl-4 pr-4">
      <div class="row media-boxes">

        <div v-for="(mediaItem, i) in mediaContent" v-bind:key="i" class="col-12 col-md-6 mb-2 mb-md-4" v-on:click="launchMedia(mediaItem.id)">
          <article class="media-box mb-2">
            <h2 class="text-white mb-3">{{mediaItem.title}}</h2>
            <figure class="mb-3">
              <img :src="'/img/media-library/small/' + mediaItem.imgUrl" :alt="mediaItem.title">
            </figure>
            <div class="media-box-content">
              <p>{{mediaItem.desc}}</p>
              <div class="d-flex align-items-center">
                <div class="mr-2">
                  <svg v-if="mediaItem.type === 'Video'" xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 40 34.22"><path d="M6.34 16.07c-2.99 0-5.42-2.43-5.42-5.42s2.43-5.42 5.42-5.42 5.42 2.43 5.42 5.42-2.43 5.42-5.42 5.42zm0-9.49c-2.24 0-4.07 1.83-4.07 4.07s1.83 4.07 4.07 4.07 4.07-1.83 4.07-4.07-1.83-4.07-4.07-4.07zm14.25 9.49c-4.08 0-7.39-3.32-7.39-7.39s3.32-7.39 7.39-7.39c4.08 0 7.39 3.32 7.39 7.39s-3.32 7.39-7.39 7.39zm0-13.43c-3.33 0-6.04 2.71-6.04 6.04s2.71 6.04 6.04 6.04 6.04-2.71 6.04-6.04-2.71-6.04-6.04-6.04zm7.88 30.3H.68a.68.68 0 01-.68-.68V15.39c0-.37.3-.68.68-.68h27.8c.37 0 .68.3.68.68v16.87c-.01.37-.31.68-.69.68zM1.35 31.58H27.8V16.07H1.35v15.51zm37.97.86c-.1 0-.19-.02-.28-.06l-10.85-4.99a.67.67 0 01-.39-.61v-6.22c0-.26.14-.49.37-.6l10.85-5.49c.21-.11.46-.1.66.03.2.12.32.34.32.58v16.7c0 .23-.12.45-.31.57-.11.06-.24.09-.37.09zm-10.17-6.1l9.5 4.37V16.16l-9.5 4.8v5.38z" /></svg>
                  <svg v-if="mediaItem.type === 'Audio'" xmlns="http://www.w3.org/2000/svg" width="40" height="35" viewBox="0 0 40 34.22"><path d="M26.27 34.22a.69.69 0 01-.39-.12L11.32 23.83H3.18a.68.68 0 01-.68-.68V12.22a.68.68 0 011.36 0v10.25h7.68c.14 0 .28.04.39.12l13.67 9.64V2.04L11.33 12.88c-.3.23-.72.17-.95-.13-.23-.3-.17-.72.13-.95L25.86.14a.679.679 0 011.09.54v32.87c0 .25-.14.48-.36.6-.11.05-.21.07-.32.07zm4.08-12.38c.11-.08 2.57-2.03 2.5-4.58-.04-1.46-.9-2.76-2.53-3.88a.676.676 0 10-.76 1.12c1.26.86 1.91 1.8 1.94 2.8.06 1.85-1.96 3.46-1.98 3.47a.68.68 0 00-.12.95c.13.17.33.26.53.26.15.01.29-.04.42-.14zm2.29 4.83c.2-.18 5.02-4.42 4.86-9.85-.09-3.05-1.73-5.83-4.89-8.26a.68.68 0 00-.95.12c-.23.3-.17.72.12.95 2.81 2.17 4.28 4.6 4.36 7.22.15 4.78-4.35 8.76-4.39 8.8-.28.25-.31.67-.07.95.13.15.32.23.51.23.16.01.32-.05.45-.16z" /></svg>
                </div>
                <p class="mb-0">{{mediaItem.length}}</p>
              </div>
            </div>
          </article>
        </div>

      </div>     
    </div>

  </div>
</template>

<script>
import mediaContent from '@/data/mediaLibrary.json'
export default {
  name: 'MediaLibrary',
  data() {
    return {
      bgClass: "",
      mediaContent: mediaContent,
      coverActive: false
    }
  },
  computed: {
  },
  methods: {
    hideNav: function () {
      this.$store.state.logoHide = true;
    },
    showNav: function () {
      this.$store.state.logoHide = false;
    },
    launchMedia: function (id) {
      var self = this;
      this.$store.state.bodyBackground = "bg-dark";
      this.coverActive = true;
      setTimeout(function(){ 
        self.$router.push('/media/' + id)
      }, 1000);
    }
  },
  mounted() {
    this.$store.state.home = false;
    this.$store.state.logoHide = false;
    this.$store.state.navHide = false;
    this.$store.state.bodyBackground = "bg-dark bg-solid";
  }
}
</script>